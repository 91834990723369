<template>
  <v-app>
    <Toolbar v-if="$route.name !== 'Play' && $route.name !== 'Swiper'" />
    <v-main class="text-center ">
      <loading />
      <v-container class="container" fluid>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
        <v-dialog v-model="support" width="300">

          <v-card>
            <v-card-title class="text-h5 grey lighten-1">
              Contact Support
            </v-card-title>

            <v-card-text class="mt-5">
              <a style="text-decoration: none;" href="mailto: support@sparport.com">Email Contact support@sparport.com</a>
              <br> Whatsapp : +966556657630
            </v-card-text>

            <v-img contain src="/qrcode.png"></v-img>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="support = false">
                Okie
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="primary" v-if="$route.name !== 'Play' && $route.name !== 'Swiper'" class="mb-5 me-5" large fab
          fixed right bottom @click="support = !support">
          <v-icon>mdi-face-agent</v-icon>
        </v-btn>
      </v-container>
    </v-main>
    <Footer v-if="$route.name !== 'Play' && $route.name !== 'Swiper'" />
  </v-app>
</template>

<script>
import Toolbar from '@/components/core/Toolbar.vue'
import Loading from '@/components/core/Loading.vue'
import Footer from '@/components/core/Footer.vue'
import Lottie from "vue-lottie";
// import SocketioService from './services/api/socketio.js';
// const { io } = require("socket.io-client");


export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      meta: [
        { name: 'msapplication-TileColor', content: '#ffc40d' },
        { name: 'theme-color', content: '#ffffff' },
        {
          name: 'apple-mobile-web-app-title',
          content: this.appTitle
        },
        { name: 'application-name', content: this.appTitle }
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png'
        },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
        { rel: 'favicon', href: '/favicon.ico' }
      ]
    }
  },
  components: {
    Toolbar,
    Loading,
    Footer,
    lottie: Lottie,
  },
  data() {
    return {
      support: false
    }
  },
  mounted() {
    console.log("VUE_APP_API_SOCKET", process.env.VUE_APP_API_SOCKET)
    // this.$store.state.profile.socket = io('ws://localhost:4000', { transports: ['websocket'] });
    // this.$socket.on("Noti", (data) => {
    //   console.log("notiSocket :::", data);
    // });

    // this.tester()

    // document.addEventListener('keydown', async (event) => {
    //   if (event.key === 'Escape' || event.keyCode === 27) {
    //     this.tester()


    //   }
    // })

    // socket.emit("Noti", "cold");



    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },
  methods: {
    async tester() {
      await axios
        .post(`${process.env.VUE_APP_API_URL}test`, {})
        .then(async (response) => {
          console.log("tester IO", response)

        })
    }
  },
  computed: {
    // appTitle() {
    //   return this.$store.getters.appTitle
    // }
  },
  created() {
    // SocketioService.setupSocketConnection();
  }
}
// mongosh "mongodb+srv://time.dyluykv.mongodb.net/history" --apiVersion 1 --username spark
// mongodb+srv://spark:rqU9ry5KHoWICMpJ@time.dyluykv.mongodb.net/test


</script>

<style>
html body {
  margin: 0;
  padding: 0;
  /* min-height: 100vh; */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

}



@font-face {
  font-family: "Kufi";
  src: url(/public/fonts/kufi.ttf);
  font-weight: bold;
}

.container {
  margin: 0;
  padding: 0;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

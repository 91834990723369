<template>
  <v-footer height="33" class="d-block text-center">
    <!-- <div>
      v{{ appVersion }} - {{ $t('footer.MADE_WITH') }}
      <v-icon small class="red--text mx-0 px-0">mdi-cards-heart</v-icon>
      &nbsp;{{ $t('footer.BY') }}:
    </div>
    <a href="http://prqxy.com">http://prqxy.com</a> -->
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0 allcol">
        <p>sparport</p>
      </v-col>
      <v-col class="ma-0 pa-0 allcol">
        <a style="text-decoration: none;" href="mailto: support@sparport.com">Contact</a>
      </v-col>
      <v-col class="ma-0 pa-0 allcol">
        <router-link style="text-decoration: none;" to="/hiring">Hiring</router-link>
      </v-col>
      <v-col class="ma-0 pa-0 allcol">
        <router-link style="text-decoration: none;" to="/policies/terms">Terms & Privacy</router-link><br>
      </v-col>

    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    // appVersion() {
    //   return this.$store.getters.appVersion
    // }
  }
}
</script>

<style scoped>
.allcol {
  height: 24px !important;
}
</style>

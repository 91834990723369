import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/src/styles/main.sass'
// import colors from 'vuetify/es5/util/colors'
// import VuetifyConfirm from 'vuetify-confirm'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const theme = JSON.parse(localStorage.getItem('dark')) || false
const opts = {
  theme: {
    dark: theme !== false
  },
  iconfont: 'mdi'
}

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#ffac34',
        secondary: '#4417E5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
})

export default new Vuetify(opts)

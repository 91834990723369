import * as types from '@/store/mutation-types'
import api from '@/services/api/upload'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
  upload: (state) => state.upload
}

const actions = {
//     getImg({ commit }) {
//     return new Promise((resolve, reject) => {
//       commit(types.SHOW_LOADING, true)
//       api
//         .getImg()
//         .then((response) => {
//           if (response.status === 200) {
//             commit(types.FILL_UPLOAD, response.data)
//             buildSuccess(null, commit, resolve)
//           }
//         })
//         .catch((error) => {
//           handleError(error, commit, reject)
//         })
//     })
//   },
//   saveImg({ commit }, payload) {
//     return new Promise((resolve, reject) => {
//       commit(types.SHOW_LOADING, true)
//       api
//         .saveImg(payload)
//         .then((response) => {
//           if (response.status === 200) {
//             commit(types.FILL_UPLOAD, response.data)
//             buildSuccess(
//               {
//                 msg: 'upload.IMAGE_SAVED_SUCCESSFULLY'
//               },
//               commit,
//               resolve
//             )
//           }
//         })
//         .catch((error) => {
//           handleError(error, commit, reject)
//         })
//     })
//   },
//   addImgData({ commit }, data) {
//     commit(types.ADD_IMAGE_DATA, data)
//   },
  saveImg({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload)
      commit(types.SHOW_LOADING, true)
      api
        .saveImg(payload)
        .then((response) => {
            console.log(response)
          if (response.status === 200) {
            // console.log(response.data)
            // commit(types.FILL_UPLOAD, response.data)
            buildSuccess(
                {
                    msg: 'resetPassword.PASSWORD_CHANGED'
                  },
              commit,
              resolve,
            )
          }
        })
        .catch((error) => {
            if (error.response.status === 404) {
                commit(types.UPLOAD_FAIL, false)
                console.log(error.response.status)
            }
          handleError(error, commit, reject)
        })
    })
  }
}

// const mutations = {
//   [types.FILL_UPLOAD](state, data) {
//     state.upload.img = data.img
//   },
//   [types.ADD_IMAGE_DATA](state, data) {
//     switch (data.key) {
//       case 'img':
//         state.upload.img = data.value
//         break
//       default:
//         break
//     }
//   }
// }

// const state = {
//   upload: {
//     img: "",
//   }
// }

export default {
//   state,
  getters,
  actions,
//   mutations
}

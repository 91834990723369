<template>
  <h3 @click="switchLocale" text>
    {{ displayLocale }}
  </h3>
</template>

<script>
export default {
  data() {
    return {
      locale: true,
    };
  },
  components: {},
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "en") {
        // console.log(this.$i18n.locale);
        this.$i18n.locale = "ar";
        this.$store.dispatch("setLocale", this.$i18n.locale);
        this.$vuetify.rtl = true;
      } else {
        // console.log("ayeeeee");
        this.$i18n.locale = "en";
        this.$store.dispatch("setLocale", this.$i18n.locale);
        this.$vuetify.rtl = false;
      }
    },
  },
  computed: {
    displayLocale() {
      if (this.$i18n.locale === "en") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      return `${this.$t("HUD.LOCALE")}`;
    },
  },
};
</script>


<style scoped>
.arTail {
  margin-bottom: 0.7%;
  color: #a1ecfb;
}

.enTail {
  letter-spacing: 0.1em;
  margin-bottom: 0.5%;
  color: #a1ecfb;
}
</style>

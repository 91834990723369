export default [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/pages/Login/index.vue')
  },
  // {
  //   path: '/signup',
  //   name: 'signup',
  //   component: () =>
  //     import(/* webpackChunkName: "signup" */ '@/pages/SignUp/index.vue')
  // },
  {
    path: '/verify/:id',
    name: 'verify',
    component: () =>
      import(/* webpackChunkName: "verify" */ '@/pages/Verify/index.vue')
  },
  {
    path: '/forgot',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ '@/pages/ForgotPass/index.vue'
      )
  },
  {
    path: '/reset/:id',
    name: 'resetPassword',
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ '@/pages/ResetPass/index.vue'
      )
  }
]

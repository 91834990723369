export default [
    {
      path: '/payment',
      name: 'Payment',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/Payment/index.vue')
    }
  ]
export default [
  {
    path: '/',
    name: 'landing',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/pages/Login/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/pages/About/index.vue')
  },
  {
    path: '/hiring',
    name: 'hiring',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/pages/Hiring/index.vue')
  }
]

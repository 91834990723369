import axios from 'axios'

export default {
  getImg() {
    return axios.get('/upload')
  },
  saveImg(payload) {
    return axios.post(`${process.env.VUE_APP_API_URL}upload/img`, payload
    //  ,{
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     },}
        )
  }
}

export default [
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Admin/index.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Dashboard/index.vue')
  },
  {
    path: '/control',
    name: 'Control',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Control/index.vue')
  },
  {
    path: '/company',
    name: 'Company',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Company/index.vue')
  },

]

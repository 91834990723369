export default [
    {
      path: '/policies/terms',
      name: 'Terms',
      meta: {
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/Terms/index.vue')
    },
    {
      path: '/policies/privacy',
      name: 'Privacy',
      meta: {
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/Privacy/index.vue')
    }
  ]
// app
export const SET_APP_VERSION = 'SET_APP_VERSION'
// locale
export const SET_LOCALE = 'SET_LOCALE'
// ticker
export const STOP_TICK = 'STOP_TICK'
export const PLAY_TICK = 'PLAY_TICK'
//exp
export const EXP_DATA = 'EXP_DATA'
export const OBJECT_DATA = 'OBJECT_DATA'
export const EXP_RESEARCH = 'EXP_RESEARCH'
export const EXP_SCORE = 'EXP_SCORE'
export const EXP_EXE = 'EXP_EXE'
export const EXP_SWIPE = 'EXP_SWIPE'
export const EXP_META = 'EXP_META'
export const EXP_RESULT = 'EXP_RESULT'
export const EXP_SWIPE_USE = 'EXP_SWIPE_USE'
export const EXP_END = 'EXP_END'
export const EXP_TIMER = 'EXP_TIMER'
export const NEW_VIEW = 'NEW_VIEW'
export const UPDATE_VIEW = 'UPDATE_VIEW'
export const CLEAR_VIEW = 'CLEAR_VIEW'

//exe
export const TIME_CARD = 'TIME_CARD'

//engine
export const SET_TAB = 'SET_TAB'
export const ACTION_TYPE = 'ACTION_TYPE'

// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// loading
export const SHOW_LOADING = 'SHOW_LOADING'
// auth
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const LOGOUT = 'LOGOUT'
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED'
export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT'
export const SHOW_CHANGE_PASSWORD_INPUTS = 'SHOW_CHANGE_PASSWORD_INPUTS'
// profile
export const FILL_PROFILE = 'FILL_PROFILE'
export const ADD_PROFILE_DATA = 'ADD_PROFILE_DATA'
// upload
export const FILL_UPLOAD = 'FILL_UPLOAD'
export const ADD_IMAGE_DATA = 'ADD_IMAGE_DATA'
export const UPLOAD_FAIL = 'UPLOAD_FAIL'

// cities
export const FILL_ALL_CITIES = 'FILL_ALL_CITIES'
// Admin - Cities
export const CITIES = 'CITIES'
export const TOTAL_CITIES = 'TOTAL_CITIES'
export const ACTIVITIES = 'ACTIVITIES'
export const TOTAL_ACTIVITIES = 'TOTAL_ACTIVITIES'
// Admin - Users
export const USERS = 'USERS'
export const TOTAL_USERS = 'TOTAL_USERS'


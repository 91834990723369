export default [
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Home/index.vue')
  },
  {
    path: '/swiper',
    name: 'Swiper',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "test" */ '@/pages/Swiper/index.vue')
  },
  {
    path: '/play/:id',
    name: 'Play',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "test" */ '@/pages/Play/index.vue')
  },
  {
    path: '/editor/:id',
    name: 'Editor',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "test" */ '@/pages/Editor/index.vue')
  },
]
